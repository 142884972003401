import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import _ from 'lodash';

interface OnBoardProps {
    stationId: number;
    schedules: any;
    studentId: number | undefined;
}

export const BoardBox = styled.div`
    display: flex;
    align-items: baseline;
`;

export const BoardTitle = styled.div`
    width: 65px;
    span {
        display: inline-block;
        border-radius: 30px;
        background-color: ${({ theme }) => theme.colors.green};
        color: ${({ theme }) => theme.colors.white};
        padding: 4px 8px;
        font-weight: bold;
        line-height: 1.1;
    }
`;
export const StudentList = styled.ul`
    margin-bottom: 10px;
    font-weight: bold;
`;

interface StudentProps {
    thisStudentId: number | undefined;
    studentId: number | undefined;
}
export const Student = styled.li<StudentProps>`
    margin-bottom: 12px;
    line-height: 1;
    :last-child {
        margin-bottom: 0;
    }
    ${({ studentId, thisStudentId }) =>
        studentId &&
        Number(thisStudentId) !== studentId &&
        css`
            color: #dedede;
            a,
            span {
                color: #dedede !important;
            }
        `};
`;

export const NoShow = styled.span`
    color: ${({ theme }) => theme.colors.orange};
    margin-left: 4px;
`;

export const OnBoard: React.FC<OnBoardProps> = ({
    stationId,
    schedules,
    studentId,
}) => {
    let onBoardCounts = 0;

    if (_.isEmpty(schedules) === false) {
        schedules.map((schedule: any) => {
            if (
                stationId === Number(schedule.pickUpStation?.id) &&
                schedule.status !== 4
            ) {
                onBoardCounts = onBoardCounts + 1;
            }
            return null;
        });
    }

    return onBoardCounts > 0 ? (
        <BoardBox>
            <BoardTitle>
                <span>승차</span>
            </BoardTitle>
            <StudentList>
                {schedules.map((schedule: any) => {
                    if (
                        stationId === Number(schedule.pickUpStation?.id) &&
                        schedule.status !== 4
                    ) {
                        return (
                            <Student
                                key={schedule.student?.id}
                                thisStudentId={schedule.student?.id}
                                studentId={studentId}
                            >
                                <Link
                                    to={`/students/${schedule.student?.id}`}
                                    title={'학생 페이지로 이동'}
                                    target={'blank'}
                                >
                                    {schedule.student?.name}
                                </Link>
                                {schedule.boardSwitch === 1
                                    ? ' (타요)'
                                    : ' (안타요)'}
                                {schedule.boardSwitch !== 1 ? (
                                    ''
                                ) : schedule.status === 0 &&
                                  schedule.boardSwitch === 1 ? (
                                    ' 탑승 대기'
                                ) : schedule.status === 3 ? (
                                    <NoShow>미승차</NoShow>
                                ) : schedule.status === 4 ? (
                                    ' 미탑승 예약'
                                ) : (
                                    ` ${schedule.timeOnBoard}`
                                )}
                            </Student>
                        );
                    } else {
                        return null;
                    }
                })}
            </StudentList>
        </BoardBox>
    ) : null;
};
