import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
    Container,
    PageTitle,
    Box,
    Accordion,
    AccordionTitle,
    AccordionTab,
} from 'styles/layout';
import BackIcon from 'assets/images/icons/back_icon.svg';
import AccordionUpIcon from 'assets/images/icons/accordion_up_icon.svg';
import AccordionDownIcon from 'assets/images/icons/accordion_down_icon.svg';
import { GlobalContext } from 'context/global';
import { Icon, BackButton } from 'styles/ui';
import { useHistory } from 'react-router-dom';
import { StudentInfo } from './StudentInfo';
import { StudentMemo } from './StudentMemo';
import { media } from 'styles/media';
import { BusService } from 'pages/BusService';
import MemberRequest from 'http/member';
const memberRequest = new MemberRequest();

interface StudentDetailProps {}

const Contents = styled.div``;

const ProfileContent = styled.div`
    padding: 30px 0;
    border-top: 1px solid #e1e1e1;
    :last-child {
        padding-bottom: 0;
    }
    ${media.desktop} {
        border-top: 0;
        flex-basis: 60%;
        padding: 10px 40px 10px 0;
        :last-child {
            border-left: 1px solid #e1e1e1;
            padding: 10px 0 10px 40px;
            flex-basis: 40%;
        }
    }
`;

const ProfileContentGroup = styled.div`
    ${media.desktop} {
        display: flex;
    }
`;

const BusServiceBox = styled(Box)`
    margin-top: 20px;
`;

const StudentBusService = styled.div`
    .Container {
        margin-left: 0;
        padding: 0;
    }
    .title {
        display: none;
    }
`;

interface parentInterface {
    name?: string;
    phone?: string;
}
// interface zonInterface {
//     id?: number;
//     name?: string;
// }
interface stationInterface {
    id?: number;
    name?: string;
    // zone?: zonInterface;
}
interface dataInterface {
    id?: number;
    name?: string;
    nickname?: string;
    ticketNumber?: string;
    phone?: string;
    kakaoId?: string;
    enrollDate?: string;
    contact?: string;
    parent?: parentInterface;
    station?: stationInterface;
}

interface paramInterface {
    id?: string;
}
export const StudentDetail: React.FC<StudentDetailProps> = () => {
    const { navToggle, setCurrentLocation } = useContext(GlobalContext);
    const history = useHistory();
    const [accordion, setAccordion] = useState({
        profile: false,
        busService: true,
    });

    const { id } = useParams<paramInterface>();
    const [data, setData] = useState<dataInterface>({});

    // 학생 리스트 가져오기
    useEffect(() => {
        const getData = async () => {
            await memberRequest.getStudentById(
                id,
                (response: any) => {
                    if (
                        response.status === -1 ||
                        (response.status === 200 && response.data.code !== 'OK')
                    ) {
                        console.log(
                            `[${response.data.code}] ${response.data.message}`
                        );
                        if (response.data.code === 'R3001') {
                            alert(
                                `[${response.data.code}] ${response.data.message}`
                            );
                            window.close();
                        }
                    } else {
                        document.title = `학생 관리`;
                        setCurrentLocation('students');
                        setData(response.data.data);
                    }
                },
                (error: any) => {
                    setData({});
                    /*
                        테스트용
                        const tmp: dataInterface = {
                            id: 1,
                            name: '홍길동',
                            nickname: '닉네임',
                            ticketNumber: '123445676575',
                            phone: '010-0000-0000',
                            kakaoId: '1231231231312313123',
                            enrollDate: '2021-06-01 00:00:00',
                            contact: '뭐지',
                            parent: {
                                name: '부모님이름',
                                phone: '010-0000-0000',
                            },
                            station: {
                                id: 1,
                                name: '정류장',
                                // zone: {
                                //     id: 1,
                                //     name: '존',
                                // },
                            },
                        };
                        setData(tmp);
                     */
                }
            );
        };

        getData();
    }, [setCurrentLocation, id]);

    return (
        <Container navToggle={navToggle}>
            <Contents>
                <PageTitle>
                    <BackButton
                        img={BackIcon}
                        width={'28px'}
                        height={'28px'}
                        onClick={e => {
                            if (history.action === 'POP') {
                                history.push('/students');
                            } else {
                                history.goBack();
                            }
                        }}
                    />
                    <span>학생 정보 관리</span>
                </PageTitle>
                <Box>
                    <Accordion toggle={accordion.profile}>
                        <AccordionTab
                            onClick={e => {
                                e.preventDefault();
                                accordion.profile === true
                                    ? setAccordion({
                                          ...accordion,
                                          profile: false,
                                      })
                                    : setAccordion({
                                          ...accordion,
                                          profile: true,
                                      });
                            }}
                        >
                            <AccordionTitle>
                                <span>{data?.name}</span> 프로필
                            </AccordionTitle>
                            <Icon
                                img={
                                    accordion.profile === true
                                        ? AccordionUpIcon
                                        : AccordionDownIcon
                                }
                                width={'24px'}
                                height={'24px'}
                            />
                        </AccordionTab>
                        <ProfileContentGroup>
                            <ProfileContent>
                                <StudentInfo data={data} />
                            </ProfileContent>
                            <ProfileContent>
                                <StudentMemo studentId={Number(id)} />
                            </ProfileContent>
                        </ProfileContentGroup>
                    </Accordion>
                </Box>

                <BusServiceBox>
                    <Accordion toggle={accordion.busService}>
                        <AccordionTab
                            onClick={e => {
                                e.preventDefault();
                                accordion.busService === true
                                    ? setAccordion({
                                          ...accordion,
                                          busService: false,
                                      })
                                    : setAccordion({
                                          ...accordion,
                                          busService: true,
                                      });
                            }}
                        >
                            <AccordionTitle>
                                <span>{data?.name}</span> 운행 목록
                            </AccordionTitle>
                            <Icon
                                img={
                                    accordion.busService === true
                                        ? AccordionUpIcon
                                        : AccordionDownIcon
                                }
                                width={'24px'}
                                height={'24px'}
                            />
                        </AccordionTab>
                        <StudentBusService>
                            {data?.id && <BusService studentId={data?.id} />}
                        </StudentBusService>
                    </Accordion>
                </BusServiceBox>
            </Contents>
        </Container>
    );
};
