import { createContext } from "react";

export interface ProfileContextType {
    updateUserAccount?: boolean | undefined;
    updateUserInfo?: boolean | undefined;
}

export const ProfileContext = createContext<ProfileContextType | any>(
    undefined
);
