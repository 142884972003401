import React, { useState, useEffect } from 'react';
import Routes from 'Routes';
import {
    getAccessToken,
    getInfo,
    getUserInfo,
    setAccessToken,
    setInfo,
    setUserInfo,
} from 'accessToken';
import { Loading } from 'components/Loading';
import _ from 'lodash';
import Moment from 'moment';

interface AppProps {}

export const App: React.FC<AppProps> = () => {
    const gAccessToken = getAccessToken();
    const gInfo = getInfo();
    const gUserInfo = getUserInfo();
    const accessToken: any = localStorage.getItem('accessToken');
    const info = String(localStorage.getItem('info'));
    const userInfo = String(localStorage.getItem('userInfo'));
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // 만료일자 체크
        const i = JSON.parse(info);

        if (_.isEmpty(i) === false) {
            const exp = Moment(i.exp * 1000).format('YYYY-MM-DD HH:mm:ss'); //만료일자
            const now = Moment(new Date()).format('YYYY-MM-DD HH:mm:ss'); //현재일자

            // 만료일 지남
            if (now > exp) {
                localStorage.removeItem('accessToken');
                localStorage.removeItem('info');
                localStorage.removeItem('userInfo');
                localStorage.removeItem('academyId');
                // setAccessToken('');
                // setInfo({});
                // setUserInfo({});
                window.location.href = '/login';
            } else {
                if (_.isEmpty(gAccessToken)) {
                    setAccessToken(accessToken);
                }
                if (_.isEmpty(gInfo)) {
                    setInfo(JSON.parse(info));
                }
                if (_.isEmpty(gUserInfo)) {
                    setUserInfo(JSON.parse(userInfo));
                }
            }
        }

        // 로딩바 제거
        const timer = setTimeout(() => {
            setLoading(false);
        }, 500);

        return () => clearInterval(timer);
    }, [gAccessToken, gInfo, gUserInfo, accessToken, info, userInfo]);

    if (loading) {
        return <Loading />;
    }

    return <Routes />;
};
