import React, { useContext, useEffect, Fragment, useState } from 'react';
import styled from 'styled-components';
import { BusServiceContext } from 'context/busService';
import { Station } from './Station';
import { Summary } from './Summary';
import { LayerMap } from './LayerMap';
import { media } from 'styles/media';
import BusRequest from 'http/bus';
const busRequest = new BusRequest();

interface BusServiceDetailProps {
    studentId: number | undefined;
}

const Course = styled.ul`
    width: 100%;
    max-height: calc(100vh - 328px);
    overflow: auto;
    padding: 60px 20px 30px;
    border-radius: 0 0 30px 30px;
    background-color: ${({ theme }) => theme.colors.white};
    ${media.tablet_} {
        max-height: calc(100vh - 395px);
    }
`;

const Detail = styled.div``;

interface paramInterface {
    date: string;
    code: string;
}

export const BusServiceDetail: React.FC<BusServiceDetailProps> = ({
    studentId,
}) => {
    const { date, busServiceId, dispatchCode, scheduels, setSchedules } =
        useContext(BusServiceContext);
    const [details, setDetails] = useState([]);

    // 10초 마다 운행리스트 & 스케줄 갱신
    useEffect(() => {
        // console.log({ dispatchCode, date });
        const getData = async () => {
            // 운행리스트
            await busRequest.getBusServiceDetailList(
                dispatchCode,
                date,
                (response: any) => {
                    if (
                        response.status === -1 ||
                        (response.status === 200 && response.data.code !== 'OK')
                    ) {
                        console.log(
                            `[${response.data.code}] ${response.data.message}`
                        );
                    } else {
                        // console.log(response.data.data);
                        setDetails(response.data.data);
                    }
                },
                (error: any) => {
                    setDetails([]);
                }
            );

            // 스케줄
            await busRequest.getSchedules(
                dispatchCode,
                date,
                (response: any) => {
                    if (
                        response.status === -1 ||
                        (response.status === 200 && response.data.code !== 'OK')
                    ) {
                        console.log(
                            `[${response.data.code}] ${response.data.message}`
                        );
                    } else {
                        setSchedules(response.data.data);
                    }
                },
                (error: any) => {
                    setSchedules([]);
                }
            );
        };
        getData();

        //const timer = setInterval(getData, 10000);

        // return () => {
        //     clearInterval(timer);
        // };
        // eslint-disable-next-line
    }, [dispatchCode]);

    return (
        <Fragment>
            <LayerMap studentId={Number(studentId)} />
            {busServiceId !== null ? (
                <Detail>
                    <Summary />

                    <Course>
                        {details.map((detail: any, index) => {
                            return (
                                <Station
                                    key={`detail-${index}`}
                                    order={index}
                                    detail={detail}
                                    schedules={scheduels}
                                    studentId={studentId}
                                ></Station>
                            );
                        })}
                    </Course>
                </Detail>
            ) : (
                <div></div>
            )}
        </Fragment>
    );
};
