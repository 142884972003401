import React, { useContext, useCallback, useEffect } from "react";
import styled, { css } from "styled-components";
import { BusServiceMap } from "./BusServiceMap";
import CloseButtonIcon from "assets/images/icons/close_button_icon.svg";
import { BusServiceContext } from "context/busService";
import { Icon } from "styles/ui";
import { Summary } from "./Summary";
import { media } from "styles/media";

interface LayerMapProps {
    studentId: number | undefined;
}

const LayerBackground = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: -1;
    opacity: 0;
    transition: 0.2s ease-in-out;
    background-color: rgba(0, 0, 0, 0.75);
    cursor: not-allowed;
`;

interface LayerMapBoxProps {
    layerMap: boolean;
}
const LayerMapBox = styled.div<LayerMapBoxProps>`
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: -1;
    opacity: 0;

    ${({ layerMap }) =>
        layerMap &&
        css`
            opacity: 1;
            z-index: 10001;
            ${LayerBackground} {
                opacity: 1;
                z-index: 10001;
            }
        `}
    .bus-service-map {
        z-index: 10002;
        width: 100vw;
    }
    .map-info {
        left: 52px;
        border-radius: 10px 10px 0 0;
        @media (max-width: 767px) {
            left: 15px;
            top: 15px;
            width: 280px;
            border-radius: 10px;
        }
    }
    .leaflet-map {
        width: 100vw;
        height: 100vh;
    }
    .leaflet-left .leaflet-control {
        margin-top: 0px;
        position: fixed;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
    }
    ${media.tablet} {
        .bus-service-map {
            width: calc(100vw - 60px);
            margin: 0 30px;
        }
        .leaflet-map {
            width: calc(100vw - 60px);
            height: calc(100vh - 60px);
            margin: 30px 0;
        }
        .leaflet-left .leaflet-control {
            position: relative;
            margin-top: 20px;
            right: auto;
            top: auto;
            transform: none;
        }
    }
`;

const CloseButton = styled.div`
    position: absolute;
    z-index: 10003;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    top: 15px;
    right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    :hover {
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.4);
    }
    ${media.tablet} {
        top: 60px;
        right: 60px;
    }
`;
interface SummaryBoxProps {
    status: number;
}
const SummaryBox = styled.div<SummaryBoxProps>`
    display: none;
    .summary {
        position: absolute;
        left: 10px;
        right: 10px;
        bottom: 0;
        z-index: 10002;
        border-radius: 0;
        color: black;
        background: rgba(255, 255, 255, 0.8);
        border-top: 1px solid #c4c4c4;
        padding: 20px 30px;
        ${media.tablet} {
            top: 98px;
            left: 82px;
            bottom: auto;
            width: 460px;
            padding: 30px 40px;
            border-radius: 0 0 10px 10px;
        }
    }
    .status {
        color: ${({ theme }: any) => theme.colors.white};
        top: 20px;
        ${({ status }) =>
            status === -1
                ? css`
                      background-color: ${({ theme }: any) =>
                          theme.colors.grey};
                  `
                : status === 0
                ? css`
                      background-color: ${({ theme }: any) =>
                          theme.colors.purple};
                  `
                : status === 1
                ? css`
                      background-color: ${({ theme }: any) =>
                          theme.colors.green};
                  `
                : css`
                      background-color: #afafaf;
                  `}
        ${media.tablet} {
            top: 30px;
        }
    }
    .open-map-button {
        display: none;
    }
    ${media.tablet} {
        display: block;
    }
`;

export const LayerMap: React.FC<LayerMapProps> = ({ studentId }) => {
    const { layerMap, setLayerMap, busServiceId, status } = useContext(
        BusServiceContext
    );

    const escFunction = useCallback(
        (event) => {
            if (event.keyCode === 27) {
                setLayerMap(false);
            }
        },
        [setLayerMap]
    );

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);

    return (
        <LayerMapBox layerMap={layerMap}>
            <CloseButton
                onClick={() => {
                    setLayerMap(false);
                }}
            >
                <Icon img={CloseButtonIcon} width={"10px"} height={"10px"} />
            </CloseButton>
            <BusServiceMap studentId={Number(studentId)} />
            {busServiceId !== null && (
                <SummaryBox status={status}>
                    <Summary />
                </SummaryBox>
            )}
            <LayerBackground
                onClick={() => {
                    setLayerMap(false);
                }}
            />
        </LayerMapBox>
    );
};
