import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { media } from 'styles/media';
import NoticeRequest from 'http/notice';
const noticeRequest = new NoticeRequest();

interface NoticeDetailProps {
    id: number;
}

const NoticeHeader = styled.div`
    padding: 20px;
    border-top: 1px solid #cecece;
    border-bottom: 1px solid #cecece;
    display: flex;
    flex-direction: column;
    ${media.tablet} {
        padding: 27px 30px;
        flex-direction: row;
        align-items: center;
    }
`;

const Title = styled.div`
    font-size: 14px;
    font-weight: bold;
    line-height: 1.6;
    margin-bottom: 10px;
    ${media.tablet} {
        font-size: 18px;
        flex: 1;
        margin-bottom: 0;
    }
`;

const Date = styled.div`
    ${media.tablet} {
        font-size: 18px;
        color: ${({ theme }) => theme.colors.grey};
        margin-left: 30px;
    }
`;

const NoticeContent = styled.div`
    background-color: #f8f8f9;
    padding: 20px;
    font-size: 12px;
    line-height: 22px;
    min-height: calc(50vh - 184px);
    color: #585858;
    ${media.tablet} {
        padding: 30px;
        font-size: 15px;
        line-height: 32px;
        min-width: 500px;
        height: calc(66vh - 280px);
        max-height: 50vh;
        overflow: auto;
    }
`;

interface dataInterface {
    id?: number;
    noticeTitle?: string;
    noticeContent?: string;
    publishDate?: string;
}
export const NoticeDetail: React.FC<NoticeDetailProps> = ({ id }) => {
    const [data, setData] = useState<dataInterface>({});

    // 공지사항 상세내역
    useEffect(() => {
        if (id) {
            noticeRequest.getBoardNotices(
                id,
                (response: any) => {
                    if (
                        response.status === -1 ||
                        (response.status === 200 && response.data.code !== 'OK')
                    ) {
                        alert(
                            `[${response.data.code}] ${response.data.message}`
                        );
                    } else {
                        setData(response.data.data);
                    }
                },
                (error: any) => {}
            );
        }
    }, [id]);

    if (data) {
        return (
            <div>
                <NoticeHeader>
                    <Title>{data?.noticeTitle}</Title>
                    <Date>{data?.publishDate}</Date>
                </NoticeHeader>
                <NoticeContent>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: `${data?.noticeContent}`,
                        }}
                    />
                </NoticeContent>
            </div>
        );
    } else {
        return <div>내용을 찾지 못했습니다.</div>;
    }
};
