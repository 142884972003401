import React, { useState, Fragment } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { Dashboard } from 'pages/Dashboard';
// import { Register } from 'pages/Register';
import { Login } from 'pages/Login';
// import { Bye } from 'pages/Bye';
import { Header } from 'components/layout/Header';
import { getAccessToken, getUserInfo } from 'accessToken';
import { Students } from 'pages/Students';
// import { Parents } from 'pages/Parents';
import { BusService } from 'pages/BusService';
import { Profile } from 'pages/Profile';
import { AuthContext } from 'context/auth';
import { StudentDetail } from 'components/students/StudentDetail';
import { SideNav } from 'components/layout/SideNav';
import { GlobalContext } from 'context/global';
import _ from 'lodash';

const Routes: React.FC = () => {
    const isAuth = getAccessToken() ? true : false;
    const existUserInfo = _.isEmpty(getUserInfo()) ? false : true;
    const [loggedIn, setLoggedIn] = useState(isAuth);
    const [academyId, setAcademyId] = useState(
        Number(localStorage.getItem('academyId'))
    );
    const [navToggle, setNavToggle] = useState<boolean>();
    const [currentLocation, setCurrentLocation] = useState<string>();
    const [registerStudentPopUp, setRegisterStudentPopUp] =
        useState<boolean>(false);

    return (
        <BrowserRouter>
            <AuthContext.Provider
                value={{
                    loggedIn,
                    setLoggedIn,
                    academyId,
                    setAcademyId,
                }}
            >
                <GlobalContext.Provider
                    value={{
                        navToggle,
                        setNavToggle,
                        currentLocation,
                        setCurrentLocation,
                        registerStudentPopUp,
                        setRegisterStudentPopUp,
                    }}
                >
                    {loggedIn && existUserInfo ? (
                        <Fragment>
                            <Header />
                            <SideNav />
                        </Fragment>
                    ) : (
                        ''
                    )}
                    <Switch>
                        <Route
                            exact
                            path="/"
                            render={() => {
                                return loggedIn ? (
                                    <Redirect to="/dashboard" />
                                ) : (
                                    <Redirect to="/login" />
                                );
                            }}
                        />
                        <Route exact path="/dashboard" component={Dashboard} />
                        {/* <Route exact path="/register" component={Register} /> */}
                        <Route exact path="/login" component={Login} />
                        {/* <Route exact path="/bye" component={Bye} /> */}
                        <Route exact path="/students" component={Students} />
                        {/* <Route exact path="/parents" component={Parents} /> */}
                        <Route exact path="/profile" component={Profile} />
                        <Route
                            exact
                            path="/busService"
                            component={BusService}
                        />
                        <Route
                            exact
                            path="/students/:id"
                            component={StudentDetail}
                        />
                    </Switch>
                </GlobalContext.Provider>
            </AuthContext.Provider>
        </BrowserRouter>
    );
};

export default Routes;
