let accessToken: string = '';
let info: object | string | null = {};
let userInfo: object | null = {};

export const setAccessToken = (s: string) => {
    accessToken = s;
};

export const getAccessToken = () => {
    return accessToken;
};

export const setInfo = (o: any) => {
    info = o;
};

export const getInfo = () => {
    return info;
};

export const setUserInfo = (o: any) => {
    userInfo = o;
};

export const getUserInfo = () => {
    return userInfo;
};
