import React, { useContext } from "react";
import { BusServiceContext } from "context/busService";
import { OnBoard } from "./OnBoard";
import { OffBoard } from "./OffBoard";
import styled, { css } from "styled-components";
import DepartureStationIcon from "assets/images/icons/departure_station_icon.svg";
import TerminatedDepartureStationIcon from "assets/images/icons/terminated_departure_station_icon.svg";
import ArrivalStationIcon from "assets/images/icons/arrival_station_icon.svg";
import TerminatedArrivalStationIcon from "assets/images/icons/terminated_arrival_station_icon.svg";
import BusIconGreen from "assets/images/icons/bus_icon_green.svg";
import { WillNotBoard } from "./WillNotBoard";

interface StationProps {
    detail: any;
    schedules: any;
    order: number;
    studentId: number | undefined;
}

export const StationName = styled.div`
    font-size: 16px;
    font-weight: bold;
    margin: -6px 0 24px 0;
    line-height: 1.6;
    cursor: pointer;
    :hover {
        font-weight: 800;
    }
`;

interface StationBoxProps {
    position?: number | undefined;
    departureTime?: string | null | undefined;
    status: number;
    order: number;
}
const StationBox = styled.div<StationBoxProps>`
    flex-basis: 70%;
    border-left: 2px solid ${({ theme }) => theme.colors.black};
    ${({ status }) =>
        status === 2
            ? css`
                  border-left: 2px solid #dedede;
              `
            : css`
                  border-left: 2px solid ${({ theme }) => theme.colors.black};
              `};
    padding: 0 0 40px 30px;
    position: relative;
    ::before {
        ${({ order }) =>
            css`
                content: "${order}";
            `}
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: bold;
        width: 20px;
        height: 20px;
        border-radius: 30px;
        position: absolute;
        left: -11px;
        top: -4px;
        line-height: 1.1;
        ${({ status }) =>
            status === 2
                ? css`
                      border: 2px solid #dedede;
                      background-color: #dedede;
                  `
                : css`
                      border: 2px solid ${({ theme }) => theme.colors.black};
                      background-color: ${({ theme }) => theme.colors.yellow};
                  `};
    }

    @-webkit-keyframes scale {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.2);
        }
        100% {
            transform: scale(1);
        }
    }

    @keyframes scale {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.2);
        }
        100% {
            transform: scale(1);
        }
    }

    @-webkit-keyframes bounce {
        0% {
            transform: translateY(-20px);
            opacity: 0;
        }
        50% {
            transform: translateY(0px);
            opacity: 1;
        }
        100% {
            transform: translateY(20px);
            opacity: 0;
        }
    }

    @keyframes bounce {
        0% {
            transform: translateY(-20px);
            opacity: 0;
        }
        50% {
            transform: translateY(0px);
            opacity: 1;
        }
        100% {
            transform: translateY(20px);
            opacity: 0;
        }
    }

    ::after {
        content: "";
        position: absolute;
        display: ${({ position }) => (position === 1 ? "block" : "none")};
        width: 30px;
        height: 30px;
        background: url(${BusIconGreen}) center center no-repeat;
        ${({ status }) =>
            status === 1 &&
            css`
                -webkit-animation: scale 2s infinite ease-in-out;
                -o-animation: scale 2s infinite ease-in-out;
                -ms-animation: scale 2s infinite ease-in-out;
                -moz-animation: scale 2s infinite ease-in-out;
                animation: scale 2s infinite ease-in-out;
            `};
        left: -16px;
        z-index: 1;
        ${({ departureTime, status }) =>
            departureTime !== "" && status === 1
                ? css`
                      top: 50%;
                      margin-top: -10px;
                      -webkit-animation: bounce 3s infinite ease-in-out;
                      -o-animation: bounce 3s infinite ease-in-out;
                      -ms-animation: bounce 3s infinite ease-in-out;
                      -moz-animation: bounce 3s infinite ease-in-out;
                      animation: bounce 3s infinite ease-in-out;
                  `
                : css`
                      top: -8px;
                  `};
    }
`;

interface StationLiProps {
    status?: number | undefined;
    order: number;
}
const Stationli = styled.li<StationLiProps>`
    display: flex;
    :first-child {
        ${StationBox} {
            ::before {
                content: "";
                width: 36px;
                height: 40px;
                border-radius: 0;
                border: none;
                ${({ status }) =>
                    status === 2
                        ? css`
                              background: url(${TerminatedDepartureStationIcon})
                                  center center no-repeat;
                          `
                        : css`
                              background: url(${DepartureStationIcon}) center
                                  center no-repeat;
                          `};
                background-size: 100%;
                top: -14px;
                left: -19px;
            }
        }
    }
    :last-child {
        ${StationBox} {
            border-left: 2px solid transparent;
            ::before {
                content: "";
                width: 36px;
                height: 40px;
                border-radius: 0;
                border: none;
                ${({ status }) =>
                    status === 2
                        ? css`
                              background: url(${TerminatedArrivalStationIcon})
                                  center center no-repeat;
                          `
                        : css`
                              background: url(${ArrivalStationIcon}) center
                                  center no-repeat;
                          `};
                background-size: 100%;
                top: -14px;
                left: -19px;
            }
            ::after {
                top: -9px;
                margin-top: 0;
            }
        }
    }
`;

interface TimeBoxProps {
    arriveTime?: string | null | undefined;
}
const TimeBox = styled.div<TimeBoxProps>`
    flex-basis: 30%;
    text-align: right;
    margin-right: 25px;
    ${({ arriveTime }) =>
        arriveTime !== "" &&
        css`
            ::after {
                content: "";
                position: absolute;
                width: 2px;
                height: 2px;
                background: #dedede;
                top: 0;
                right: 0;
            }
        `}
`;

const TimeGroup = styled.div`
    display: inline-block;
    width: 66px;
    text-align: left;
    min-height: 115px;
`;

const EstimatedArrivalTime = styled.div`
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 30px;
`;

const ActualArrivalTime = styled.div`
    color: #888888;
    margin-bottom: 12px;
`;

const ActualDepartureTime = styled.div`
    margin-bottom: 12px;
`;

export const Station: React.FC<StationProps> = ({
    detail,
    schedules,
    order,
    studentId,
}) => {
    const { busServiceId, setCenter, status } = useContext(BusServiceContext);
    const stationId = Number(detail.station?.id);

    return busServiceId !== null ? (
        <Stationli key={detail.id} status={status} order={order}>
            <TimeBox arriveTime={detail.arriveTime}>
                <TimeGroup>
                    <EstimatedArrivalTime>
                        {detail.arriveTargetTime}
                    </EstimatedArrivalTime>
                    {detail.arriveTime !== "" && (
                        <ActualArrivalTime>
                            {detail.arriveTime} 도착
                        </ActualArrivalTime>
                    )}
                    {detail.departureTime !== "" && (
                        <ActualDepartureTime>
                            {detail.departureTime} 출발
                        </ActualDepartureTime>
                    )}
                </TimeGroup>
            </TimeBox>
            <StationBox
                position={Number(detail.position)}
                departureTime={detail.departureTime}
                status={status}
                order={order}
            >
                <StationName
                    title={"정류장 지도 중앙 위치 >"}
                    onClick={(e) => {
                        e.preventDefault();
                        setCenter({
                            lat: Number(detail.station?.lat),
                            lng: Number(detail.station?.lng),
                        });
                    }}
                >
                    {detail.station?.name}
                </StationName>
                <OnBoard
                    stationId={stationId}
                    schedules={schedules}
                    studentId={studentId}
                />
                <OffBoard
                    stationId={stationId}
                    schedules={schedules}
                    studentId={studentId}
                />
                <WillNotBoard
                    stationId={stationId}
                    schedules={schedules}
                    studentId={studentId}
                />
            </StationBox>
        </Stationli>
    ) : (
        <div></div>
    );
};
