import React, { Fragment, useContext, useEffect, useState } from 'react';
import { BoxHeader, Box, BoxFooter } from 'styles/layout';
import { Label, Input, ButtonLine } from 'styles/ui';
import styled from 'styled-components';
import { UpdateUserInfo } from './UpdateUserInfo';
import { ProfileContext } from 'context/profile';
import { getUserInfo } from 'accessToken';
interface UserInfoProps {}

const BoxBody = styled.div``;

interface ExInfoProps {
    isEditing: boolean;
}
const ExInfo = styled.div<ExInfoProps>`
    display: ${({ isEditing }) => (isEditing ? 'none' : 'block')};
`;

export const InfoList = styled.ul`
    width: 100%;
`;

export const Info = styled.li`
    margin-bottom: 26px;
    :last-child {
        margin-bottom: 0;
    }
`;

interface UpdateUserInfoProps {
    isEditing: boolean;
}
const UpdateInfo = styled.div<UpdateUserInfoProps>`
    display: ${({ isEditing }) => (isEditing ? 'block' : 'none')};
`;
interface dataInterface {
    academy?: {
        id?: number;
        name?: string;
    };
    id?: number;
    name?: string;
    email?: string;
    phone?: string;
    role?: string;
    enrolledAt?: string;
}
export const UserInfo: React.FC<UserInfoProps> = () => {
    const { updateUserInfo, setUpdateUserInfo } = useContext(ProfileContext);
    const [data, setData] = useState<dataInterface>();

    // 로그인시 가져왔던 회원정보 가져오기
    useEffect(() => {
        const tmp = getUserInfo();
        if (tmp) {
            setData(tmp);
        }
    }, []);

    return (
        <Fragment>
            <Box>
                <BoxHeader>
                    <h2>관리자 기본정보</h2>
                </BoxHeader>
                <ExInfo isEditing={updateUserInfo}>
                    <BoxBody>
                        <InfoList>
                            <Info>
                                <Label htmlFor="name">이름</Label>
                                <Input
                                    id="name"
                                    type="text"
                                    value={data?.name || ''}
                                    autoComplete="user name"
                                    isValid={true}
                                    disabled={true}
                                />
                            </Info>
                            <Info>
                                <Label htmlFor="phone">전화번호</Label>
                                <Input
                                    type="text"
                                    value={data?.phone || ''}
                                    autoComplete="current-phone"
                                    isValid={true}
                                    disabled={true}
                                />
                            </Info>
                        </InfoList>
                    </BoxBody>
                    <BoxFooter>
                        <ButtonLine
                            onClick={() => {
                                setUpdateUserInfo(true);
                            }}
                        >
                            수정
                        </ButtonLine>
                    </BoxFooter>
                </ExInfo>
                <UpdateInfo isEditing={updateUserInfo}>
                    <UpdateUserInfo
                        email={data?.email || ''}
                        name={data?.name || ''}
                        phone={data?.phone || ''}
                    />
                </UpdateInfo>
            </Box>
        </Fragment>
    );
};
