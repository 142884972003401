import styled, { css } from "styled-components";
import checkbox_off from "../assets/images/common/checkbox_off.svg";
import checkbox_hover from "../assets/images/common/checkbox_hover.svg";
import checkbox_on from "../assets/images/common/checkbox_on.svg";

export const Checkbox = styled.div`
    display: flex;
    align-items: center;
`;

export const CheckboxLabel = styled.label`
    color: #cecece;
    margin: 2px 0 0 5px;
    :hover {
        cursor: pointer;
    }
`;

export const CheckboxInput = styled.input`
    width: 18px;
    height: 18px;
    background: url(${checkbox_off}) center center no-repeat;
    background-size: cover;
    border: none;
    margin: 0;
    :hover {
        background: url(${checkbox_hover}) center center no-repeat;
        cursor: pointer;
    }
    :checked {
        background: url(${checkbox_on}) center center no-repeat;
    }
    :checked + ${CheckboxLabel} {
        color: black;
    }
`;

export const Label = styled.label`
    display: block;
    margin-bottom: 10px;
    letter-spacing: 0.5px;
    color: ${({ theme }) => theme.colors.blue};
    font-weight: 800;
`;

export const Comment = styled.span`
    color: ${({ theme }) => theme.colors.orange};
`;

interface InputProps {
    isValid?: boolean | undefined;
    disabled?: boolean | undefined;
}

export const Input = styled.input<InputProps>`
    display: block;
    padding: 12px;
    width: 100%;
    height: 40px;
    font-size: 16px;
    color: ${({ isValid, disabled, theme }) =>
        disabled ? "#999999" : theme.colors.black};
    border: 1px solid
        ${({ isValid, disabled }) =>
            disabled ? "#f6f7f9" : isValid ? "#e7e7e7" : "#ff7900"};
    border-radius: 5px;
    background: ${({ isValid, disabled }) =>
        disabled ? "#f6f7f9" : isValid ? "#ffffff" : "#fff2e6"};
    -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
    ::placeholder {
        color: #cecece;
        font-size: 14px;
        letter-spacing: 0;
    }
    :focus,
    :hover {
        ${({ disabled }) =>
            disabled
                ? css``
                : css`
                      outline: none;
                      border: 1px solid ${(props) => props.theme.colors.yellow};
                      -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
                      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
                  `}
    }
    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
        border: 1px solid ${(props) => props.theme.colors.yellow};
        -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
        transition: background-color 5000s ease-in-out 0s;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    }
`;

interface IconProps {
    img: string;
    width?: string | undefined;
    height?: string | undefined;
}

export const Icon = styled.div<IconProps>`
    background: url(${(props) => props.img}) center center no-repeat;
    display: block;
    background-size: cover;
    width: ${({ width }) => (width ? width : "20px")};
    height: ${({ height }) => (height ? height : "20px")};
`;

export const Button = styled.button`
    width: auto;
    display: flex;
    font-weight: bold;
    align-items: center;
    height: 40px;
    padding: 0 23px;
    border-radius: 20px;
    border: none;
    background: ${({ theme }) => theme.colors.yellow};
    :hover {
        -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.18);
        -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.18);
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.18);
    }
`;

export const ButtonLine = styled(Button)`
    color: ${({ theme }) => theme.colors.yellow};
    border: 2px solid ${({ theme }) => theme.colors.yellow};
    background-color: ${({ theme }) => theme.colors.white};
`;

export const ButtonGrey = styled(Button)`
    color: ${({ theme }) => theme.colors.white};
    border: 2px solid ${({ theme }) => theme.colors.lightGrey};
    background-color: ${({ theme }) => theme.colors.lightGrey};
`;

export const Textarea = styled.textarea`
    width: 100%;
    border: 1px solid #e7e7e7;
    padding: 20px;
`;

export const BackButton = styled(Icon)`
    cursor: pointer;
`;
