import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { RouteComponentProps } from "react-router-dom";
import { AuthContext } from "context/auth";
import { StudentList } from "components/students/StudentList";
import { GlobalContext } from "context/global";
import { Container, PageTitle } from "styles/layout";

const Contents = styled.div`
    width: 100%;
    max-width: 980px;
`;

export const Students: React.FC<RouteComponentProps> = ({ history }) => {
    const { navToggle, setCurrentLocation } = useContext(GlobalContext);

    useEffect(() => {
        document.title = `학생관리`;
        setCurrentLocation("students");
    }, [setCurrentLocation]);

    const { loggedIn } = useContext(AuthContext);
    if (!loggedIn) {
        history.push("/login");
    }

    return (
        <Container navToggle={navToggle}>
            <Contents>
                <PageTitle>학생 관리</PageTitle>
                <StudentList></StudentList>
            </Contents>
        </Container>
    );
};
