import { createContext } from "react";

export interface BusServiceContextType {
    busServiceId: number | null;
    dispatchCode: string | undefined;
}

export const BusServiceContext = createContext<BusServiceContextType | any>(
    undefined
);
