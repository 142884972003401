import Request from "./request";

class NoticeRequest extends Request {
    /**
     * 학원 공지 리스트 (useGetPartnerNoticesQuery)
     * params
     *      page    페이지번호
     *      size    몇개씩볼지
     * 
     * callback 
     *      콜백함수
     * 
     * errorCallback
     *      오류콜백함수
     * example
     *      /v2/notices?page=2&size=5
     *      공지일 기준 최신일자 정렬
     */
    getPartnerNotices = (data, callback, errorCallback) => {
        const page = data.page ? data.page : 0;
        const size = data.size ? data.size : 5;
        const request = {
            callback,
            errorCallback
        };
        this.get(`/notices?page=${page}&size=${size}`, request);
    }
    /**
     * 학원 공지 상제 정보 (useGetBoardNoticeQuery)
     * params
     *      id      공지사항 pk
     * 
     * callback 
     *      콜백함수
     * 
     * errorCallback
     *      오류콜백함수
     */
    getBoardNotices = (id, callback, errorCallback) => {
        const request = {
            callback,
            errorCallback
        };
        this.get(`/notices/${id}`, request);
    }
}

export default NoticeRequest;
