import React, { Fragment, useContext, useState, useEffect } from 'react';
import { BoxHeader, Box, BoxFooter } from 'styles/layout';
import { Label, Input, ButtonLine } from 'styles/ui';
import styled from 'styled-components';
import { UpdateUserAccount } from './UpdateUserAccount';
import { ProfileContext } from 'context/profile';
import { getUserInfo } from 'accessToken';

interface UserAccountProps {}

const BoxBody = styled.div``;

interface ExAccountProps {
    isEditing: boolean;
}
const ExAccount = styled.div<ExAccountProps>`
    display: ${({ isEditing }) => (isEditing ? 'none' : 'block')};
`;

export const InfoList = styled.ul`
    width: 100%;
`;

export const Info = styled.li`
    margin-bottom: 26px;
    :last-child {
        margin-bottom: 0;
    }
`;

interface UpdateUserAccountProps {
    isEditing: boolean;
}
const EditAccount = styled.div<UpdateUserAccountProps>`
    display: ${({ isEditing }) => (isEditing ? 'block' : 'none')};
`;
interface dataInterface {
    academy?: {
        id?: number;
        name?: string;
    };
    id?: number;
    name?: string;
    email?: string;
    phone?: string;
    role?: string;
    enrolledAt?: string;
}
export const UserAccount: React.FC<UserAccountProps> = () => {
    const { updateUserAccount, setUpdateUserAccount } =
        useContext(ProfileContext);
    const [data, setData] = useState<dataInterface>();

    // 로그인시 가져왔던 회원정보 가져오기
    useEffect(() => {
        const tmp = getUserInfo();
        if (tmp) {
            setData(tmp);
        }
    }, []);

    return (
        <Fragment>
            <Box>
                <BoxHeader>
                    <h2>관리자 접속계정</h2>
                </BoxHeader>
                <ExAccount isEditing={updateUserAccount}>
                    <BoxBody>
                        <InfoList>
                            <Info>
                                <Label htmlFor="email">이메일</Label>
                                <Input
                                    id="email"
                                    type="text"
                                    value={data?.email || ''}
                                    autoComplete="user email"
                                    isValid={true}
                                    disabled={true}
                                />
                            </Info>
                            <Info>
                                <Label htmlFor="password">비밀번호</Label>
                                <Input
                                    type="text"
                                    value={'••••••••' || ''}
                                    autoComplete="current-password"
                                    style={{ letterSpacing: 5 }}
                                    isValid={true}
                                    disabled={true}
                                />
                            </Info>
                        </InfoList>
                    </BoxBody>
                    <BoxFooter>
                        <ButtonLine
                            onClick={() => {
                                setUpdateUserAccount(true);
                            }}
                        >
                            비밀번호 변경
                        </ButtonLine>
                    </BoxFooter>
                </ExAccount>
                <EditAccount isEditing={updateUserAccount}>
                    <UpdateUserAccount email={String(data?.email)} />
                </EditAccount>
            </Box>
        </Fragment>
    );
};
