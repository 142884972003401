import React, {useContext, useEffect, useState} from 'react';
import {Map, TileLayer, Marker, Popup, Polyline} from 'react-leaflet';
import {BusServiceContext} from 'context/busService';
import L from 'leaflet';
import DepartureStationIcon from 'assets/images/icons/departure_station_icon.svg';
import StationIcon from 'assets/images/icons/map/map_station_icon.svg';
import ArrivalStationIcon from 'assets/images/icons/arrival_station_icon.svg';
import DepartureStationIconMini from 'assets/images/icons/map/map_departure_station_icon_mini.svg';
import ArrivalStationIconMini from 'assets/images/icons/map/map_arrival_station_icon_mini.svg';
import StationIconMini from 'assets/images/icons/map/map_station_icon_mini.svg';
import CourseLineIcon from 'assets/images/icons/map/map_course_line_icon_mini.svg';
import GPSLineIcon from 'assets/images/icons/map/map_GPS_line_icon_mini.svg';
import BusIcon from 'assets/images/icons/map/map_bus_icon.svg';
import styled from 'styled-components';
import {OnBoard} from './OnBoard';
import {OffBoard} from './OffBoard';
import {WillNotBoard} from './WillNotBoard';
import {StationName} from './Station';
import {Icon} from 'styles/ui';
import BusRequest from 'http/bus';
import _ from 'lodash';

const busRequest = new BusRequest();

interface BusServiceMapProps {
    studentId: number | undefined;
}

const getCourseLine: any | undefined = (
    courseLatLng: { coordinates: any[]} | undefined
) => {
    const latCount: any = courseLatLng?.coordinates.length;
    let courseLine : any[] = [];
    courseLatLng?.coordinates.map((latlan: any) => {
    //    console.log(latlan)
        let latLng = [latlan[1], latlan[0]];
        courseLine.push(latLng);
    })
    return courseLine;
};

const getBounds: any | undefined = (stations: any | undefined) => {
    let stationBounds: Array<any> = [];
    stations.map((station: any) => {
        let stationLatLng = [station?.lat, station?.lng];
        stationBounds.push(stationLatLng);
        return true;
    });
    const bounds = L.latLngBounds(stationBounds);
    return bounds;
};

const StyledMap = styled(Map)`
    border-radius: 30px;
    width: 100%;
    height: calc(100vh - 150px);
`;

const MapBox = styled.div`
    position: relative;
`;

const MapInfo = styled.div`
    background: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    padding: 10px;
    position: absolute;
    top: 20px;
    left: 46px;
    z-index: 1002;
    max-width: calc(100% - 56px);
    -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
    ul {
        display: flex;
        align-items: center;
        /* justify-content: space-between; */
        flex-wrap: wrap;
        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 4px 10px;
            ${Icon} {
                margin-right: 4px;
            }
        }
    }
`;

const StyledPopup = styled(Popup)`
    ${StationName} {
        padding-right: 15px;
        max-width: 200px;
    }
`;

interface CourseLineInterface {
    lat?: string;
    lng?: string;
}

interface CourseInfoInterface {
    studentId?: number | any;
    courseName?: string;
    totalDistance?: number;
    totalDuration?: number;
    courseLine?: CourseLineInterface;
}

interface CourseStationsInterface {
    id?: number;
    name?: string;
    address?: string;
    roadAddress?: string;
    lat?: number;
    lng?: number;
}

interface BusGpsLocationInterface {
    busId?: number;
    dispatchId?: number;
    status?: number;
    lat?: number;
    lng?: number;
    masterId?: number;
    // startStation :
    // destinationStation :
}

export const BusServiceMap: React.FC<BusServiceMapProps> = ({studentId}) => {
    const {busServiceId, courseCode, center, setCenter, scheduels, status, setStatus} =
        useContext(BusServiceContext);
    const [bounds, setBounds] = useState();
    const [courseInfo, setCourseInfo] = useState<CourseInfoInterface[]>([]);
    const [courseStations, setCourseStations] = useState<CourseStationsInterface[]>([]);
    const [busGpsLocation, setBusGpsLocation] =
        useState<BusGpsLocationInterface>({});

    // 1. 코스정보 가져오기
    // const getCourseInfoData = async () => {
    //     await busRequest.getCourseInfo(
    //         courseCode,
    //         (response: any) => {
    //             if (
    //                 response.status === -1 ||
    //                 (response.status === 200 && response.data.code !== 'OK')
    //             ) {
    //                 console.log(
    //                     `[${response.data.code}] ${response.data.message}`
    //                 );
    //             } else {
    //                 setCourseInfo(response.data.data);
    //             }
    //         },
    //         (error: any) => {
    //             setCourseInfo({});
    //             /*
    //                 테스트용
    //                 const tmp: any = {
    //                     courseName: '태민학원_20210428-태민학원차량1-월-18:00-등원',
    //                     totalDistance: 0,
    //                     totalDuration: 0,
    //                     courseLine: [
    //                         {
    //                             lat: '37.50352901',
    //                             lng: '127.02456305',
    //                         },
    //                         {
    //                             lat: '37.48732541',
    //                             lng: '127.02877424',
    //                         },
    //                     ],
    //                 };
    //                 setCourseInfo(tmp);
    //             */
    //         }
    //     );
    // };

    // 2. 코스의 정류장 리스트 API
    const getCourseStationsData = async () => {
        await busRequest.getCourseStations(
            courseCode,
            (response: any) => {
                if (
                    response.status === -1 ||
                    (response.status === 200 && response.data.code !== 'OK')
                ) {
                    console.log(
                        `[${response.data.code}] ${response.data.message}`
                    );
                } else {
                    setCourseStations(response.data.data);
                    getCourseLineGraphHopper(response.data.data);
                }
            },
            (error: any) => {
                setCourseStations([]);
            }
        );

        const getCourseLineGraphHopper = (stationData: any) => {
            let points: any[] = [];

            stationData.map((station: any) => {
                let pos = [station.lng, station.lat];
                points.push(pos);
            });
            busRequest.getCourseLine(
                {
                    "points": points,
                    "points_encoded": false
                },

                (response: any) => {
                    if (response.status !== 200 ) {
                        console.log('getCourseLine : ' +
                            `[${response.data.code}] ${response.data.message}`
                        );
                    } else {
                        setCourseInfo(response.data.paths);
                    }
                },
                (error: any) => {
                    setCourseStations([]);
                    /*
                        테스트용
                        const tmp: any = {
                            courseName: '태민학원_20210428-태민학원차량1-월-18:00-등원',
                            totalDistance: 0,
                            totalDuration: 0,
                            courseLine: [
                                {
                                    id: 476,
                                    name: 'Academy',
                                    address: '수정테스트',
                                    roadAddress: '',
                                    lat: 37.418777882652535,
                                    lng: 127.1256987695506,
                                },
                                {
                                    id: 477,
                                    name: '여수초등학교',
                                    address: '경기 성남시 중원구 여수동 595',
                                    roadAddress: '',
                                    lat: 37.41950491098405,
                                    lng: 127.1323877133677,
                                },
                            ],
                        };
                        setCourseStations(tmp);
                    */
                }
            );
        }
    };

    useEffect(() => {
        if (courseCode) {
            const getData = async () => {
                //await getCourseInfoData();
                await getCourseStationsData();
            };
            getData();
        }
        // eslint-disable-next-line
    }, [courseCode]);

    // 10초마다 운행중인 버스의 위치 갱신
    useEffect(() => {
        // console.log('====== busServiceId');
        let pointCnt = 0;
        const getBusGps = async () => {

            console.log(status);
            if( status === 0 || status === undefined){
                return;
            }

            if (courseInfo !== undefined && courseInfo !== null && courseInfo.length > 0) {
                // @ts-ignore
                let coursePoints = courseInfo[0].points.coordinates;

                if( coursePoints.length > 0 && coursePoints.length != pointCnt) {
                    let point = coursePoints[pointCnt];
                    console.log(point);
                    let latLng = {lat : point[1], lng : point[0]};

                    setBusGpsLocation(latLng);
                    setCenter(latLng);
                    pointCnt = pointCnt + 1;
                }else{
                    setBusGpsLocation({});
                    setStatus(0);
                    return clearInterval(timer);
                }
            }

        };

        const timer = setInterval(getBusGps, 500);

        return () => {
            clearInterval(timer);
        };

    }, [busServiceId, status]);

    useEffect(() => {
        if (_.isEmpty(courseStations) === false) {
            setBounds(getBounds(courseStations));
        }
    }, [courseStations]);

    const getStationIcon = (
        index: number,
        station: any,
        stationLength: number
    ) => {
        const icon = L.divIcon({
            className: 'station-icon',
            html: `<div className="index" style="
                    width: 30px;
                    background: url(${
                index === 0
                    ? DepartureStationIcon
                    : index === stationLength - 1
                    ? ArrivalStationIcon
                    : StationIcon
            }) center center no-repeat;
                    background-size:contain;
                    width:48px;
                    height:48px;
                    display:inline-flex;
                    align-items:center;
                    justify-content:center;
                    transform:translate3d(calc(-50% + 6px),calc(-100% + 12px),0);
                    position:relative;
                "><div style="color:white;font-size:18px;font-weight:800;position: absolute; top: 50%; left: 50%; transform: translate(calc(-50% - 1px), calc(-50% - 3px));">${
                index === 0 || index === stationLength - 1 ? '' : index
            }</div><div className="name" style="display:none;width:100px;padding:10px;margin-left:-50px;background:pink">${
                station.name
            }</div></div>`,
        });
        return icon;
    };

    const getBusIcon = () => {
        const icon = L.divIcon({
            className: 'bus-icon',
            html: `<div className="index" style="
                    width: 30px;
                    background: url(${BusIcon}) center center no-repeat;
                    background-size:contain;
                    width:40px;
                    height:40px;
                    display:inline-flex;
                    align-items:center;
                    justify-content:center;
                    transform:translate3d(calc(-50% + 6px),calc(-100% + 12px),0);
                    position:relative;
                    z-index:1000
                ">`,
        });
        return icon;
    };

    let courseLine: any = [];
    if (courseInfo !== undefined && courseInfo !== null && courseInfo.length > 0) {
        // @ts-ignore
        courseLine = getCourseLine(courseInfo[0].points);
    }

    return busServiceId !== null ? (
        <MapBox className="bus-service-map">
            <MapInfo className="map-info">
                <ul>
                    <li>
                        <Icon
                            img={DepartureStationIconMini}
                            width={'20px'}
                            height={'20px'}
                        />
                        시작 지점
                    </li>
                    <li>
                        <Icon
                            img={ArrivalStationIconMini}
                            width={'20px'}
                            height={'20px'}
                        />
                        도착 지점
                    </li>
                    <li>
                        <Icon
                            img={StationIconMini}
                            width={'20px'}
                            height={'20px'}
                        />
                        정류장
                    </li>
                    <li>
                        <Icon
                            img={CourseLineIcon}
                            width={'20px'}
                            height={'20px'}
                        />
                        예상 경로
                    </li>
                    <li>
                        <Icon
                            img={GPSLineIcon}
                            width={'20px'}
                            height={'20px'}
                        />
                        실제 경로
                    </li>
                </ul>
            </MapInfo>
            <StyledMap
                viewport={{
                    center: [center.lat || 0, center.lng || 0],
                    zoom: 15,
                }}
                bounds={bounds}
                className={'leaflet-map'}
            >
                <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />

                {_.isEmpty(courseStations) === false &&
                courseStations.map((station: any, index: number) => {
                    const lat: number = parseFloat(station.lat);
                    const lng: number = parseFloat(station.lng);
                    const stationLength = courseStations.length;
                    const stationId = Number(station.id);

                    return (
                        <Marker
                            key={station.id}
                            position={[lat, lng]}
                            icon={getStationIcon(
                                index,
                                station,
                                stationLength
                            )}
                        >
                            <StyledPopup>
                                <StationName
                                    title={'정류장 지도 중앙 위치 >'}
                                    onClick={e => {
                                        e.preventDefault();
                                        setCenter({
                                            lat: Number(station?.lat),
                                            lng: Number(station?.lng),
                                        });
                                    }}
                                >
                                    {station.name}
                                </StationName>
                                <OnBoard
                                    stationId={stationId}
                                    schedules={scheduels}
                                    studentId={studentId}
                                />
                                <OffBoard
                                    stationId={stationId}
                                    schedules={scheduels}
                                    studentId={studentId}
                                />
                                <WillNotBoard
                                    stationId={stationId}
                                    schedules={scheduels}
                                    studentId={studentId}
                                />
                            </StyledPopup>
                        </Marker>
                    );
                })}

                {courseLine && (
                    <Polyline color="black" positions={courseLine} weight={8}/>
                )}
                {courseLine && (
                    <Polyline
                        color="#ffcd00"
                        positions={courseLine}
                        weight={4}
                    />
                )}
                {/* {gpsLine && (
                    <Polyline color="black" positions={gpsLine} weight={6} />
                )}
                {gpsLine && (
                    <Polyline color="#ff7900" positions={gpsLine} weight={4} />
                )} */}

                {busGpsLocation && status === 1 && (
                    <Marker
                        position={[
                            busGpsLocation.lat || 0,
                            busGpsLocation.lng || 0,
                        ]}
                        icon={getBusIcon()}
                        zIndexOffset={1000}
                    ></Marker>
                )}
            </StyledMap>
        </MapBox>
    ) : (
        <div></div>
    );
};
