import Request from "./request";

class BusRequest extends Request {
    /**
     * 학원버스 운행 상태별 카운트 (useGetBusServiceStatusCountQuery)
     * params
     *      academyId   학원 pk (토큰활용)
     * 
     * callback 
     *      콜백함수
     * 
     * errorCallback
     *      오류콜백함수
     */
    getBusStatusCount = (callback, errorCallback) => {
        const request = {
            callback,
            errorCallback
        };
        this.get("/dashboard/bus/status", request);
    }
    /**
     * 운행 리스트 (useGetBusServiceListQuery)
     * params
     *      date        조회일자 (YYYY-MM-DD) (필수)
     *      studentId   학생 pk (선택)
     * 
     * callback 
     *      콜백함수
     * 
     * errorCallback
     *      오류콜백함수
     * 
     * 참고
     *      status      운행정보상태 (0: 대기중, 1: 운행중, 2: 운행종료)
     */
    getBusServiceList = (data, callback, errorCallback) => {
        const request = {
            callback,
            errorCallback
        };
        let param = `date=${data.date}`;
        if (typeof data.studentId === "number") {
            param += `&studentId=${data.studentId}`;
        }
        this.get("/bus-operations?" + param, request);
    }

    /**
     * 운행 상세 리스트 (useGetBusServiceDetailQuery)
     * params
     *      date        조회일자 (YYYY-MM-DD) (필수)
     *      code        배차코드 (필수)
     * 
     * callback 
     *      콜백함수
     * 
     * errorCallback
     *      오류콜백함수
     * 
     * 참고
     *      status      운행정보상태 (0: 대기중, 1: 운행중, 2: 운행종료)
     */
    getBusServiceDetailList = (code, date, callback, errorCallback) => {
        const request = {
            callback,
            errorCallback
        };
        this.get(`/bus-operations/detail?code=${code}&date=${date}`, request);
    }

    /**
     * 스케줄 리스트 (useGetSchedulesQuery)
     * params
     *      date                조회일자 (YYYY-MM-DD) (필수)
     *      dispatchCode        배차코드 (필수)
     * 
     * callback 
     *      콜백함수
     * 
     * errorCallback
     *      오류콜백함수
     */
    getSchedules = (dispatchCode, date, callback, errorCallback) => {
        const request = {
            callback,
            errorCallback
        };
        this.get(`/bus-operations/schedules?code=${dispatchCode}&date=${date}`, request);
    }

    /**
     * 코스 정보 (useGetCourseInfoQuery)
     * params
     *      courseCode       코스코드
     * 
     * callback 
     *      콜백함수
     * 
     * errorCallback
     *      오류콜백함수
     */
    getCourseInfo = (data, callback, errorCallback) => {
        const request = {
            callback,
            errorCallback
        };
        this.get(`/bus-operations/course/${data}`, request);
    }

    /**
     * 코스의 정류장 리스트 정보 (useGetCourseInfoQuery)
     * params
     *      courseCode       코스코드
     * 
     * callback 
     *      콜백함수
     * 
     * errorCallback
     *      오류콜백함수
     */

    getCourseLine = (data, callback, errorCallback) => {
        const request = {
            data,
            callback,
            errorCallback
        };
        this.getGraphHopper(request);
    }

    /**
     * 코스의 정류장 리스트 정보 (useGetCourseInfoQuery)
     * params
     *      courseCode       코스코드
     *
     * callback
     *      콜백함수
     *
     * errorCallback
     *      오류콜백함수
     */

    getCourseStations = (data, callback, errorCallback) => {
        const request = {
            callback,
            errorCallback
        };
        this.get(`/bus-operations/course/${data}/stations`, request);
    }

    /**
     * 운행중인 버스의 위치 (useGetBusGpsLocationQuery)
     * params
     *      courseCode       코스코드
     * 
     * callback 
     *      콜백함수
     * 
     * errorCallback
     *      오류콜백함수
     * 
     * 참고
     *      현재 운행중일때만 데이터 반환 / 없다면 404 발생
     */
    getBusGpsLocation = (data, callback, errorCallback) => {
        const request = {
            callback,
            errorCallback
        };
        this.get(`/bus-operations/${data}/bus/location`, request);
    }

    /**
     * 운행정보 (useGetBusServiceMasterQuery)
     * params
     *      id          busServiceMasterId          
     * 
     * callback 
     *      콜백함수
     * 
     * errorCallback
     *      오류콜백함수
     * 
     * 참고
     *      현재 운행중일때만 데이터 반환 / 없다면 404 발생
     */
    getBusServiceMaster = (id, callback, errorCallback) => {
        const request = {
            callback,
            errorCallback
        };
        this.get(`/bus-operations/${id}`, request);
    }

    /**
     * 운행정보2 - status가 0(운행대기)에 대한 운행정보 (useGetBusServiceMasterQuery)
     * 조금 더 상세히 말하자면 busServiceId가 0 인경우에 대한 운행정보는 아래 api를 통해 운행정보를 불러옴
     * 
     * params
     *      code       배차코드 (필수) - dispatchCode
     * 
     * callback 
     *      콜백함수
     * 
     * errorCallback
     *      오류콜백함수
     * 
     * 참고
     *      현재 운행중일때만 데이터 반환 / 없다면 404 발생
     */
    getBusServiceMasterBydispatchCode = (code, callback, errorCallback) => {
        const request = {
            callback,
            errorCallback
        };
        this.get(`/bus-operations/car-dispatch/${code}`, request);
    }

}

export default BusRequest;
