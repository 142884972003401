import React from 'react';
import styled from 'styled-components';
import { BoardTitle, BoardBox, StudentList, Student, NoShow } from './OnBoard';
import { Link } from 'react-router-dom';
import _ from 'lodash';

interface WillNotBoardProps {
    stationId: number;
    schedules: any;
    studentId: number | undefined;
}

const WillNotBoardTitle = styled(BoardTitle)`
    span {
        background-color: ${({ theme }) => theme.colors.orange};
    }
`;

export const WillNotBoard: React.FC<WillNotBoardProps> = ({
    stationId,
    schedules,
    studentId,
}) => {
    let WillNotBoardCounts = 0;

    if (_.isEmpty(schedules) === false) {
        schedules.map((schedule: any) => {
            if (
                (stationId === Number(schedule.pickUpStation?.id) &&
                    schedule.status === 4) ||
                (stationId === Number(schedule.takeOffStation?.id) &&
                    schedule.status === 4)
            ) {
                WillNotBoardCounts = WillNotBoardCounts + 1;
            }
            return null;
        });
    }

    return WillNotBoardCounts === 0 ? null : (
        <BoardBox>
            <WillNotBoardTitle>
                <span>미탑승</span>
            </WillNotBoardTitle>
            <StudentList>
                {schedules.map((schedule: any) => {
                    if (
                        (stationId === Number(schedule.pickUpStation?.id) &&
                            schedule.status === 4) ||
                        (stationId === Number(schedule.takeOffStation?.id) &&
                            schedule.status === 4)
                    ) {
                        return (
                            <Student
                                key={schedule.student?.id}
                                thisStudentId={schedule.student?.id}
                                studentId={studentId}
                            >
                                <Link
                                    to={`/students/${schedule.student?.id}`}
                                    title={'학생 페이지로 이동'}
                                    target={'blank'}
                                >
                                    {schedule.student?.name}
                                </Link>
                                {/* {schedule.boardSwitch === 1
                                    ? " (타요)"
                                    : " (안타요)"} */}
                                {schedule.status === 0 ? (
                                    ' 탑승대기'
                                ) : schedule.status === 3 ? (
                                    <NoShow>미승차</NoShow>
                                ) : schedule.status === 4 ? (
                                    ' 미탑승 예약'
                                ) : (
                                    ` ${schedule.timeOnBoard}`
                                )}
                            </Student>
                        );
                    } else {
                        return null;
                    }
                })}
            </StudentList>
        </BoardBox>
    );
};
