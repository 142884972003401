import React, {useContext, useEffect, useState} from 'react';
import {BusServiceContext} from 'context/busService';
import styled from 'styled-components';
import {css} from 'styles/theme-components';
import OpenMapButtonIcon from 'assets/images/icons/open_map_button_icon.svg';
import {Icon} from 'styles/ui';
import BusRequest from 'http/bus';

const busRequest = new BusRequest();

interface SummaryProps {
    layerMap?: boolean | undefined;
}

interface StatusProps {
    status?: number | undefined;
}

const statusColors = css<StatusProps>`
    ${({status}) =>
    status === -1
        ? css`
                  ${({theme}: any) => theme.colors.grey}
              `
        : status === 0
        ? css`
                  ${({theme}: any) => theme.colors.purple}
              `
        : status === 1
            ? css`
                  ${({theme}: any) => theme.colors.green}
              `
            : '#e9e9e9'};
`;

const SummaryBox = styled.div<StatusProps>`
    position: relative;
    color: ${({theme}) => theme.colors.white};
    ${({status}) =>
    status === 2
        ? css`
                  color: #afafaf;
              `
        : null}
    font-weight: 800;
    border-radius: 20px 20px 0 0;
    padding: 40px 40px 54px;
    background-color: ${statusColors};
`;
const BusName = styled.h2`
    margin-bottom: 12px;
`;
const DispatchName = styled.h1`
    /* font-size: 17px; */
    margin-bottom: 20px;
    line-height: 1.2;
    width: calc(100% - 60px);
`;
const Manager = styled.div`
    margin-bottom: 12px;
`;
const Driver = styled.div``;

const BusStart = styled.div<SummaryProps>`
    position: absolute;
    top: 120px;
    right: 30px;
    font-size: 15px;
    font-weight: 800;
    color: ${({theme}) => theme.colors.green};
    ${({layerMap}) =>
    layerMap === false
        ? css`display: flex;`
        : css`display: none;`}
    
    justify-content: center;
    align-items: center;
    line-height: 1.2;
    width: 60px;
    height: 60px;
    background-color: ${({theme}) => theme.colors.white};
    text-align: center;
    border-radius: 30px;
`;

const Status = styled.div<StatusProps>`
    position: absolute;
    top: 40px;
    right: 30px;
    font-size: 15px;
    font-weight: 800;
    color: ${statusColors};
    ${({status}) =>
    status === 2
        ? css`
                  color: #afafaf;
              `
        : null}
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.2;
    width: 60px;
    height: 60px;
    background-color: ${({theme}) => theme.colors.white};
    text-align: center;
    border-radius: 30px;
`;

const OpenMapButton = styled.div`
    background-color: ${({theme}) => theme.colors.white};
    border-radius: 30px;
    font-size: 16px;
    font-weight: 800;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 80px);
    margin: 0 40px;
    position: absolute;
    bottom: -25px;
    left: 0;
    -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    z-index: 2;
    color: #000;
    :hover {
        -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.3);
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.3);
    }
    ${Icon} {
        margin-right: 10px;
    }
`;

interface dispatchInterface {
    name?: string;
}

interface busInterface {
    name?: string;
    driver?: string;
    driverPhone?: string;
}

interface managerInterface {
    name?: string;
    phone?: string;
}

interface busServiceInterpace {
    dispatch?: dispatchInterface;
    bus?: busInterface;
    manager?: managerInterface;
    status?: number;
}

export const Summary: React.FC<SummaryProps> = () => {
    const {busServiceId, status, setStatus, layerMap, setLayerMap, dispatchCode, setStartDemo} =
        useContext(BusServiceContext);
    const [busService, setBusService] = useState<busServiceInterpace>({});

    useEffect(() => {
        setBusService({});
        console.log({busServiceId, dispatchCode});

        if (busServiceId === 0) {
            busRequest.getBusServiceMasterBydispatchCode(
                dispatchCode,
                (response: any) => {
                    if (
                        response.status === -1 ||
                        (response.status === 200 && response.data.code !== 'OK')
                    ) {
                        console.log(
                            `[${response.data.code}] ${response.data.message}`
                        );
                    } else {
                        setBusService(response.data.data);
                        setStatus(Number(response.data.data.status));
                        setLayerMap(false);
                    }
                },
                (error: any) => {
                    console.log('여기');
                    setBusService({});
                    /*
                        테스트용
                        const tmp: any = {
                            dispatch: {
                                name: 'TEST Academy_Test-1호_화요일_11:00_등원',
                            },
                            bus: {
                                name: 'Test-1호',
                                driver: '니콜라스',
                                driverPhone: '010-0000-0000',
                            },
                            manager: {
                                name: '최성희',
                                phone: '010-5053-3226',
                            },
                            status: 1,
                        };
                        setBusService(tmp);
                        setStatus(Number(tmp.status));
                        */
                }
            );
        } else {
            busRequest.getBusServiceMaster(
                busServiceId,
                (response: any) => {
                    if (
                        response.status === -1 ||
                        (response.status === 200 && response.data.code !== 'OK')
                    ) {
                        console.log(
                            `[${response.data.code}] ${response.data.message}`
                        );
                    } else {
                        setBusService(response.data.data);
                        setStatus(Number(response.data.data.status));
                    }
                },
                (error: any) => {
                    console.log('여기');
                    setBusService({});
                    /*
                        테스트용
                        const tmp: any = {
                            dispatch: {
                                name: 'TEST Academy_Test-1호_화요일_11:00_등원',
                            },
                            bus: {
                                name: 'Test-1호',
                                driver: '니콜라스',
                                driverPhone: '010-0000-0000',
                            },
                            manager: {
                                name: '최성희',
                                phone: '010-5053-3226',
                            },
                            status: 1,
                        };
                        setBusService(tmp);
                        setStatus(Number(tmp.status));
                        */
                }
            );
        }
        // eslint-disable-next-line
    }, [busServiceId, dispatchCode]);

    // const dispatchName = busService.dispatch?.name?.split(
    //     `${busService.bus?.name}_`
    // )[1];

    const checkStatus = (status: any) => {
        if( status === 0){
            setStatus(1);
        }else{
            setStatus(0);
        }
    }

    return (
        <SummaryBox status={status} className="summary">
            <BusName>[{busService.bus?.name || ''}]</BusName>
            <DispatchName>{busService.dispatch?.name || ''}</DispatchName>
            <Manager>
                • 동승자:{busService.manager?.name || ''}(
                {busService.manager?.phone || ''})
            </Manager>
            <Driver>
                • 운전사:{busService.bus?.driver || ''}(
                {busService.bus?.driverPhone || ''})
            </Driver>
            <Status status={status} className={`status ${status}`}>
                {status === -1 ? (
                    '운행전'
                ) : status === 0 ? (
                    <span>
                        운행
                        <br/>
                        대기
                    </span>
                ) : status === 1 ? (
                    '운행중'
                ) : (
                    <span>
                        운행
                        <br/>
                        완료
                    </span>
                )}
            </Status>
            <BusStart layerMap={layerMap}
                      onClick={() => {
                          checkStatus(status);
                      }}>
                {status === 0 ? (
                    <span>
                        데모<br/>시작
                    </span>
                ) : status === 1 ? (
                        <span>
                        데모<br/>종료
                    </span>
                    ) :
                    <span>
                        데모<br/>시작
                    </span>
                }

            </BusStart>
            <OpenMapButton
                onClick={() => {
                    setLayerMap(true);
                }}
                className="open-map-button"
            >
                <Icon img={OpenMapButtonIcon} width={'12px'} height={'17px'}/>
                운행 지도 보기
            </OpenMapButton>
        </SummaryBox>
    );
};
