import React, { useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';
import { GlobalContext } from 'context/global';
import { AuthContext } from 'context/auth';
import { Container, Box, SearchBox } from 'styles/layout';
import { Search } from 'components/layout/Search';
import { BusServiceStatus } from 'components/dashboard/BusServiceStatus';
import { StudentsStatus } from 'components/dashboard/StudentsStatus';
import { Notice } from 'components/dashboard/Notice';
import { media } from 'styles/media';
import { DashboardContext } from 'context/dashboard';
import { getUserInfo } from 'accessToken';
import _ from 'lodash';

const DashBoardContainer = styled(Container)`
    ${media.tablet_} {
        padding-top: 113px;
    }
`;
const Contents = styled.div`
    ${media.desktop} {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    ${SearchBox} {
        position: absolute;
        top: 52px;
        width: calc(100% - 20px);
        z-index: 1;
    }
`;

const BoxMargin = css`
    margin-top: 20px;
`;

const BusServiceStatusBox = styled(Box)`
    ${BoxMargin}
    position: relative;
`;

const StudentsStatusBox = styled.div`
    ${BoxMargin}
`;

const NoticeBox = styled(Box)`
    ${BoxMargin}
    ${media.desktop} {
        flex-basis: calc(40% - 10px);
    }
`;

const Copy = styled.div`
    display: none;
    ${media.tablet} {
        display: inline-block;
        flex: 1;
        flex-basis: 100%;
        font-size: 30px;
        font-weight: 200;
        line-height: 1.6;
        margin: 80px 14px 40px;
        strong {
            font-weight: 800;
        }
    }
    ${media.desktop} {
        margin-top: 40px;
        flex-basis: 100%;
    }
`;

const BoxGroup = styled.div`
    margin-top: 50px;
    ${media.tablet} {
        margin-top: 0;
    }
    ${media.desktop} {
        flex-basis: calc(60% - 10px);
    }
`;

interface dataInterface {
    academy?: {
        id?: number;
        name?: string;
    };
    id?: number;
    name?: string;
    email?: string;
    phone?: string;
    role?: string;
    enrolledAt?: string;
}
export const Dashboard: React.FC<RouteComponentProps> = ({ history }) => {
    const [data, setData] = useState<dataInterface>();
    const { loggedIn } = useContext(AuthContext);
    const existUserInfo = _.isEmpty(getUserInfo()) ? false : true;
    if (!loggedIn) {
        history.push('/login');
    }
    const { navToggle, setCurrentLocation } = useContext(GlobalContext);
    const [noticePopUp, setNoticePopUp] = useState(false);

    useEffect(() => {
        document.title = `옐로우버스 학원관리`;
        setCurrentLocation('dashboard');
    });

    useEffect(() => {
        const tmp = getUserInfo();
        if (tmp) {
            setData(tmp);
        }
    }, [loggedIn]);

    return (
        loggedIn && (
            <DashboardContext.Provider
                value={{
                    noticePopUp,
                    setNoticePopUp,
                }}
            >
                {existUserInfo && (
                    <DashBoardContainer navToggle={navToggle}>
                        <Contents>
                            <SearchBox>
                                <Search />
                            </SearchBox>
                            <Copy>
                                안녕하세요! {data?.academy?.name} 관리자님,
                                <br />
                                <strong>옐로우버스</strong> 입니다.
                            </Copy>
                            <BoxGroup>
                                <BusServiceStatusBox>
                                    <BusServiceStatus />
                                </BusServiceStatusBox>
                                <StudentsStatusBox>
                                    <StudentsStatus />
                                </StudentsStatusBox>
                            </BoxGroup>
                            <NoticeBox>
                                <Notice />
                            </NoticeBox>
                        </Contents>
                    </DashBoardContainer>
                )}
            </DashboardContext.Provider>
        )
    );
};
