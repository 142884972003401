import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { AuthContext } from 'context/auth';
import { BusServiceList } from 'components/busService/BusServiceList';
import { BusServiceDetail } from 'components/busService/BusServiceDetail';
import { BusServiceContext } from 'context/busService';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import { BusServiceMap } from 'components/busService/BusServiceMap';
import { GlobalContext } from 'context/global';
import { Container, PageTitle } from 'styles/layout';
import { media } from 'styles/media';
import { BackButton } from 'styles/ui';
import CalendarIcon from 'assets/images/icons/calendar_icon.svg';
import BackIcon from 'assets/images/icons/back_icon.svg';

const Contents = styled.div`
    width: 100%;
`;

const DatePickerDiv = styled.div`
    position: absolute;
    top: 24px;
    right: 24px;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    padding-left: 34px;
    padding: 8px 0 8px 34px;
    background: url(${CalendarIcon}) left 10px center no-repeat;
    input {
        border: none;
        border-left: 1px solid #e1e1e1;
        font-size: 14px;
        font-weight: 600;
        width: 140px;
        height: 30px;
        padding: 0;
        height: 14px;
    }
    ${media.tablet} {
        top: 29px;
        right: 40px;
    }
`;

const List = styled.ul`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    ${media.desktop} {
        flex-wrap: nowrap;
    }
`;

interface LiProps {
    active?: boolean | undefined;
}

const Li = styled.li<LiProps>`
    display: inline-block;
    position: relative;
    flex-basis: 100%;
    display: ${({ active }) => (active ? 'block' : 'none')};
    :last-child {
        display: none;
    }
    ${media.tablet} {
        display: block;
        flex-basis: calc(50% - 10px);
    }
    ${media.desktop} {
        flex-basis: calc(33.3333333334% - 10px);
        :last-child {
            flex-basis: calc(33.3333333334% - 10px);
            display: block;
        }
    }
    ${media.fulldesktop} {
        flex-basis: calc(25% - 10px);
        :last-child {
            flex-basis: calc(50% - 10px);
        }
    }
    ${media.extrafulldesktop} {
        flex-basis: 443px;
        margin-right: 20px;
        :last-child {
            margin-right: 0;
            flex: 1;
        }
    }
`;

interface BackStepButtonProps {
    active?: boolean | undefined;
}
const BackStepButton = styled(BackButton)<BackStepButtonProps>`
    margin-right: 10px;
    display: ${({ active }) => (active ? 'block' : 'none')};
    ${media.tablet} {
        display: none;
    }
`;

interface BusServiceProps {
    studentId?: number | any;
}

export const BusService: React.FC<BusServiceProps> = ({ studentId }) => {
    const { navToggle, setCurrentLocation } = useContext(GlobalContext);
    const history = useHistory();

    useEffect(() => {
        document.title = `운행관제`;
        const location = window.location.href.split('/');
        if (location[location.length - 1] === 'busService') {
            setCurrentLocation('busService');
        }
    });

    const { loggedIn, academyId } = useContext(AuthContext);
    if (!loggedIn) {
        history.push('/login');
    }

    const [rawDate, setRawDate] = useState(new Date());
    const date = dayjs(rawDate).format('YYYY-MM-DD');

    const [status, setStatus] = useState();
    const [busServiceId, setBusServiceId] = useState(null);
    const [dispatchCode, setDispatchCode] = useState('');
    const [courseCode, setCourseCode] = useState();
    const [center, setCenter] = useState({
        lat: 37.42002671,
        lng: 127.13061966,
    });
    const [scheduels, setSchedules] = useState();
    const [layerMap, setLayerMap] = useState(false);
    const [stepActive, setStepActive] = useState({
        list: true,
        detail: false,
    });

    return (
        <BusServiceContext.Provider
            value={{
                status,
                setStatus,
                academyId,
                date,
                busServiceId,
                setBusServiceId,
                dispatchCode,
                setDispatchCode,
                courseCode,
                setCourseCode,
                center,
                setCenter,
                scheduels,
                setSchedules,
                layerMap,
                setLayerMap,
                setStepActive,
            }}
        >
            <Container navToggle={navToggle} className="Container">
                <Contents>
                    <PageTitle className="title">
                        <BackStepButton
                            img={BackIcon}
                            width={'28px'}
                            height={'28px'}
                            onClick={() => {
                                setStepActive({
                                    list: true,
                                    detail: false,
                                });
                            }}
                            active={stepActive.detail}
                        />
                        버스 운행 관제
                    </PageTitle>
                    <List>
                        <Li active={stepActive.list}>
                            <DatePickerDiv>
                                <DatePicker
                                    selected={rawDate}
                                    onChange={(rawDate: Date) => {
                                        setRawDate(rawDate);
                                        setBusServiceId(null);
                                    }}
                                    dateFormat="yyyy-MM-dd EE"
                                    placeholderText="운행일을 선택하세요"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                />
                            </DatePickerDiv>
                            <BusServiceList studentId={Number(studentId)} />
                        </Li>
                        <Li active={stepActive.detail}>
                            <BusServiceDetail studentId={Number(studentId)} />
                        </Li>
                        <Li>
                            <BusServiceMap studentId={Number(studentId)} />
                        </Li>
                    </List>
                </Contents>
            </Container>
        </BusServiceContext.Provider>
    );
};
