import React, { Fragment, useState, useEffect } from 'react';
import { BoxHeader, BoxFooter } from 'styles/layout';
import { BoxBody } from 'components/dashboard/BusServiceStatus';
import styled from 'styled-components';
import { Button, Textarea } from 'styles/ui';
import useInput from 'hooks/useInput';
import MemberRequest from 'http/member';
const memberRequest = new MemberRequest();

interface StudentMemoProps {
    studentId: number;
}

const UpadatedTime = styled.div`
    line-height: 1.3;
    color: ${({ theme }) => theme.colors.grey};
    text-align: right;
`;

const MemoTextarea = styled(Textarea)`
    width: 100%;
    border: 1px solid #e7e7e7;
    padding: 20px;
`;

interface dataInterface {
    id?: number;
    content?: string;
    updatedAt?: string;
    createdAt?: string;
}

export const StudentMemo: React.FC<StudentMemoProps> = ({ studentId }) => {
    const [data, setData] = useState<dataInterface>({});
    const [hasMemo, setHasMemo] = useState<boolean>(false);
    const [memoLoading, setMemoLoading] = useState<boolean>(false);
    const memo = useInput('');

    // 학생 리스트 가져오기
    useEffect(() => {
        const getData = async () => {
            await setMemoLoading(true);
            await memberRequest.getMemo(
                studentId,
                (response: any) => {
                    if (
                        response.status === -1 ||
                        (response.status === 200 && response.data.code !== 'OK')
                    ) {
                        console.log(
                            `[${response.data.code}] ${response.data.message}`
                        );
                    } else {
                        setHasMemo(
                            response.data.data?.content.length > 0
                                ? true
                                : false
                        );
                        setData(response.data.data);
                        memo.setValue(response.data.data.content);
                    }
                },
                (error: any) => {
                    setData({});
                    /*
                        테스트용
                        const tmp: dataInterface = {
                            id: 1,
                            content: '메모메모',
                            updatedAt: '2021-06-02 00:00:00',
                            createdAt: '2021-06-02 00:00:00',
                        };
                        setData(tmp);
                        memo.setValue(tmp.content);
                    */
                }
            );
            await setMemoLoading(false);
        };

        getData();
         // eslint-disable-next-line 
    }, [studentId]);

    // 메모 생성
    const createMemo = async () => {
        await setMemoLoading(true);
        const param = {
            content: memo.value,
        };
        await memberRequest.addMemo(
            Number(studentId),
            param,
            (response: any) => {
                console.log(response);
                if (
                    response.status === -1 ||
                    (response.status === 200 && response.data.code !== 'OK')
                ) {
                    alert(`[${response.data.code}] ${response.data.message}`);
                } else {
                    alert('새로운 메모를 생성하였습니다.');
                }
            },
            (error: any) => {}
        );
        await setMemoLoading(false);
    };

    // 메모 수정
    const updateMemo = async () => {
        const param = {
            memoId: hasMemo ? Number(data?.id) : -1,
            content: memo.value,
        };
        await memberRequest.updateMemo(
            Number(studentId),
            param,
            (response: any) => {
                console.log(response);
                if (
                    response.status === -1 ||
                    (response.status === 200 && response.data.code !== 'OK')
                ) {
                    alert(`[${response.data.code}] ${response.data.message}`);
                } else {
                    alert('새로운 메모를 생성하였습니다.');
                }
            },
            (error: any) => {}
        );
    };

    return (
        <Fragment>
            <BoxHeader>
                <h2>학생관련 메모</h2>
            </BoxHeader>
            <form
                onSubmit={async e => {
                    e.preventDefault();

                    if (
                        !window.confirm(
                            '입력하신 새로운 메모를 저장하시겠습니까?\n(기존 메모는 저장되지 않습니다.)'
                        )
                    ) {
                        return;
                    }

                    try {
                        if (hasMemo) {
                            await updateMemo();
                        } else {
                            await createMemo();
                        }
                    } catch (e) {
                        const errorCode = e.graphQLErrors[0].extensions?.code;
                        if (errorCode === 'NO_MEMO') {
                            alert('메모가 존재하지 않습니다.');
                            return;
                        }
                    }
                }}
            >
                <BoxBody>
                    <MemoTextarea
                        id={String(data?.id) || ''}
                        name="studentMemo"
                        {...memo}
                        rows={10}
                        placeholder={'학생관련 메모를 입력하세요.'}
                    />
                </BoxBody>
                <BoxFooter>
                    <Button type="submit">
                        {memoLoading ? '메모 저장중⋅⋅⋅' : '메모 저장'}
                    </Button>
                    <UpadatedTime>
                        {hasMemo && '최근 저장'}
                        <br />
                        {data?.updatedAt || ''}
                    </UpadatedTime>
                </BoxFooter>
            </form>
        </Fragment>
    );
};
