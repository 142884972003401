import { createGlobalStyle } from "styled-components";
import { reset } from "styled-reset";

const GlobalStyle = createGlobalStyle`
    ${reset}
    /* http://meyerweb.com/eric/tools/css/reset/ 
    v2.0 | 20110126
    License: none (public domain)
    */
    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed, 
    figure, figcaption, footer, header, hgroup, 
    menu, nav, output, ruby, section, summary,  
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }
    /* HTML5 display-role reset for older browsers */
    article, aside, details, figcaption, figure, 
    footer, header, hgroup, menu, nav, section {
        display: block;
    }
    body {
        line-height: 1;
    }
    ol, ul {
        list-style: none;
    }
    blockquote, q {
        quotes: none;
    }
    blockquote:before, blockquote:after,
    q:before, q:after {
        content: '';
        content: none;
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }

    /* wJk */
    *, *::before, *::after {
        box-sizing: border-box;
    }

    body{
        color:#000;
        font-family:NanumSquareRound, Helvetica, Arial, sans-serif;
        font-size:12px;
        background:#f7f7f7;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    a,
    a:visited,
    a:active{
        text-decoration:none;
        color:#000
    }

    a:hover{
        color: #ffcd00;
        cursor: pointer;
    }

    input{
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border:1px solid #999;
        height:36px
    }

    select {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
            border:1px solid #999;
            border-radius:0;
            height:36px
    }

    label{
        margin-bottom:5px
    }

    button{
        width:100%;
        padding:10px
    }
    button:hover{
        cursor: pointer;
    }

    h1 {
        font-size: 28px;
        font-weight:800
    }

    h2 {
        font-size: 18px;
        font-weight:800
    }

    h3 {
        font-size: 16px;
        font-weight:800
    }

    h4 {
        font-size: 14px;
        font-weight:600
    }

    /* date-picker */
    .react-datepicker-wrapper input{padding:10px}
    .react-datepicker-wrapper input:hover{border-color:lightblue; cursor: pointer;}
    .react-datepicker__header__dropdown{padding:10px 0}
    .react-datepicker-popper{left:auto!important;right:0!important}
    .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow{top:4px}

    .leaflet-left .leaflet-control{margin-top:20px}
    .leaflet-container a.leaflet-popup-close-button{top:6px;right:8px}
`;

export default GlobalStyle;
