import styled, { css } from "styled-components";
import { GlobalContextType } from "context/global";
import { media } from "styles/media";
import { Button } from "./ui";

export const Container = styled.div<GlobalContextType>`
    padding: 62px 10px 10px;
    transition: 0.3s ease-in-out;

    ${media.tablet_} {
        margin-left: 68px;
        padding: 120px 20px 20px;
        ${({ navToggle }) =>
            navToggle &&
            css`
                margin-left: 210px;
            `};
    }
`;

export const SearchBox = styled.div`
    ${media.tablet_} {
        display: none;
    }
`;

export const PageTitle = styled.h1`
    font-size: ${({ theme }) => theme.fontSizes.h2};
    margin: 0 0 20px;
    padding: 0 10px;
    display: flex;
    height: 28px;
    align-items: center;
    span {
        margin-left: 10px;
    }
    ${media.tablet_} {
        font-size: ${({ theme }) => theme.fontSizes.h1};
        margin: 32px 0 50px;
        position: fixed;
        top: 20px;
        z-index: 10000;
    }
`;

export const Box = styled.div`
    width: 100%;
    border-radius: 30px;
    -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08);
    background-color: ${({ theme }) => theme.colors.white};
    padding: 30px 24px;
    ${media.tablet} {
        padding: 40px;
    }
`;

export const BoxHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    color: ${({ theme }) => theme.colors.blue};
`;

export const BoxFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row-reverse;
    margin-top: 30px;
    ${Button} {
        margin-left: 8px;
    }
`;

interface AccordionProps {
    toggle?: boolean | undefined;
}

export const Accordion = styled.div<AccordionProps>`
    ${({ toggle }) =>
        toggle
            ? css`
                  height: auto;
              `
            : css`
                  height: 20px;
                  overflow: hidden;
                  ${media.desktop} {
                      height: auto;
                  }
              `}
`;

export const AccordionTab = styled.div`
    display: flex;
    justify-content: space-between;
    font-weight: 800;
    font-size: 18px;
    padding-bottom: 17px;
    cursor: pointer;
    ${media.desktop} {
        display: none;
    }
`;

export const AccordionTitle = styled.div`
    color: ${({ theme }) => theme.colors.blue};
    span {
        color: ${({ theme }) => theme.colors.black};
        margin-right: 2px;
    }
`;
