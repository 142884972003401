import React from "react";
import styled, { css } from "styled-components";
import { Box } from "styles/layout";
import CloseButtonIcon from "assets/images/icons/close_button_icon.svg";
import { Icon } from "styles/ui";
import { media } from "styles/media";

interface PopUpProps {
    title: string;
    contents: any;
    id: boolean;
    handler: any;
    hasBackButton?: boolean | undefined;
}

interface ContainerProps {
    open: boolean;
}

const Container = styled.div<ContainerProps>`
    display: ${({ open }) => (open === true ? "block" : "none")};
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10002;
    padding: 40px 10px;
`;

const BackgroundLayer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    z-index: 100;
`;

const Contents = styled(Box)`
    position: absolute;
    max-width: calc(100vw - 20px);
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    padding: 30px;
    max-height: calc(100vh - 79px);
    z-index: 100;
    border-radius: 10px;
    ${media.tablet} {
        width: auto;
        max-width: 600px;
        padding: 40px;
    }
`;

interface PopUpHeaderProps {
    hasBackButton?: boolean | undefined;
}
const PopUpHeader = styled.div<PopUpHeaderProps>`
    margin-bottom: 20px;
    h2 {
        color: ${({ theme }) => theme.colors.blue};
        ${({ hasBackButton }) =>
            hasBackButton &&
            css`
                margin-left: 40px;
            `}
    }
`;

const PopUpCloseButton = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    padding: 30px;
    cursor: pointer;
`;

const PopUpBody = styled.div``;

export const PopUp: React.FC<PopUpProps> = ({
    title,
    contents,
    id,
    handler,
    hasBackButton,
}) => {
    return (
        <Container open={id}>
            <BackgroundLayer
                onClick={() => {
                    handler(false);
                }}
            />
            <Contents>
                <PopUpHeader hasBackButton={hasBackButton}>
                    <h2>{title}</h2>
                    <PopUpCloseButton
                        onClick={() => {
                            handler(false);
                        }}
                    >
                        <Icon
                            img={CloseButtonIcon}
                            width={"12px"}
                            height={"12px"}
                        ></Icon>
                    </PopUpCloseButton>
                </PopUpHeader>
                <PopUpBody>{contents}</PopUpBody>
            </Contents>
        </Container>
    );
};
