import baseStyled, {
    // css,
    // CSSProp,
    ThemedStyledInterface,
} from 'styled-components';

const colors: { [key: string]: string } = {
    yellow: '#FFCD00',
    orange: '#ff7900',
    green: '#079f00',
    black: '#000000',
    blue: '#174490',
    purple: '#800080',
    grey: '#666666',
    lightGrey: '#999999',
    white: '#ffffff',
};

const colorsSecondary = {};

const fontSizes: { [key: string]: string } = {
    h1: '28px',
    h2: '18px',
    h3: '16px',
    h4: '14px',
};

const fontWeight: { [key: string]: string } = {
    h1: '800',
    h2: '800',
    h3: '800',
    h4: '600',
};

const theme = {
    colors,
    colorsSecondary,
    fontSizes,
    fontWeight,
};

export type Theme = typeof theme;
export const styled = baseStyled as ThemedStyledInterface<Theme>;
export default theme;
