import Request from "./request";

class StudentRequest extends Request {
    /**
     * 총 학생수 (useGetStudentsCountQuery)
     * params
     *      academyId   (토큰활용)
     * 
     * callback 
     *      콜백함수
     * 
     * errorCallback
     *      오류콜백함수
     */
    getStudentsCount = (callback, errorCallback) => {
        const request = {
            callback,
            errorCallback
        };
        this.get("/dashboard/students/cnt/total", request);
    }

    /**
     * 오늘 총 신규 학생 (useGetStudentsCountQuery)
     * params
     *      academyId   (토큰활용)
     * 
     * callback 
     *      콜백함수
     * 
     * errorCallback
     *      오류콜백함수
     */
    getNewStudentsCount = (callback, errorCallback) => {
        const request = {
            callback,
            errorCallback
        };
        this.get("/dashboard/students/cnt/new", request);
    }

    /**
     * 신규학생 생성  (useRegisterStudentMutation)
     * params
     *      parentCode
     *      name
     *      nickName
     *      phone
     *      stationId
     * 
     * callback 
     *      콜백함수
     * 
     * errorCallback
     *      오류콜백함수
     */
    addStudent = (data, callback, errorCallback) => {
        const request = {
            data,
            callback,
            errorCallback
        };
        this.post("/students", request);
    }

}

export default StudentRequest;
