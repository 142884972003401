import React, { useState, Fragment, useRef } from 'react';
import { Icon } from 'styles/ui';
import styled from 'styled-components';
import SearchIconYellow from 'assets/images/icons/search_icon_yellow.svg';
// import { AuthContext } from 'context/auth';
import { Link } from 'react-router-dom';
import CancelButtonIcon from 'assets/images/icons/close_button_icon.svg';
import { media } from 'styles/media';
import MemberRequest from 'http/member';
const memberRequest = new MemberRequest();

interface SearchProps {}

const SearchBox = styled.div`
    position: relative;
    border-radius: 20px;
    background: #fff;
    border: 0px solid #fff;
    :focus,
    :hover,
    :active {
        outline: none;
        -webkit-box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.1);
    }
`;

export const SearchInput = styled.input`
    padding-left: 50px;
    background: transparent url(${SearchIconYellow}) left 20px center no-repeat;
    background-size: 18px 18px;
    border: none;
    width: 100%;
    height: 40px;
    border-radius: 30px;
    font-size: 16px;
    :focus,
    :hover {
        outline: none;
    }
    ${media.tablet} {
        font-size: 14px;
    }
`;

const Results = styled.div`
    max-height: 300px;
    overflow: auto;
    z-index: 10;
    width: 100%;
    background: white;
    border-radius: 0 0 20px 20px;

    ul {
        padding: 10px 0;
        border-top: 1px solid #ececec;
    }
    li a {
        display: inline-block;
        width: 100%;
        padding: 10px 24px;
        line-height: 1.6;
        :hover {
            background-color: #f3f3f3;
            color: black;
        }
        span {
            color: ${({ theme }) => theme.colors.grey};
            margin-left: 10px;
            display: inline-block;
        }
    }
`;

const CancleButton = styled.div`
    position: absolute;
    right: 5px;
    top: 0;
    padding: 15px;
    cursor: pointer;
`;

interface dataInterface {
    id?: number;
    name?: string;
    phone?: string;
    parent?: parentInterface;
}

interface parentInterface {
    name?: string;
    phone?: string;
}

export const Search: React.FC<SearchProps> = () => {
    // const { academyId } = useContext(AuthContext);
    const [keyword, setKeyword] = useState('');
    const [result, setResult] = useState(false);
    const searchInput = useRef<HTMLInputElement>(null);
    const [data, setData] = useState<dataInterface[]>([]);

    // input onChange event
    const onChangeInput = async (e: React.KeyboardEvent) => {
        await memberRequest.getStudents(
            keyword,
            (response: any) => {
                if (
                    response.status === -1 ||
                    (response.status === 200 && response.data.code !== 'OK')
                ) {
                    alert(`[${response.data.code}] ${response.data.message}`);
                } else {
                    setData(response.data.data);
                }
            },
            (error: any) => {
                /*
                    테스트용
                    const tmp: dataInterface[] = [
                        {
                            id: 1,
                            name: '감자',
                            phone: '010-0000-0000',
                            parent: {
                                name: '감자엄마',
                                phone: '010-1111-1111',
                            },
                        },
                    ];
                    setData(tmp);
                */
            }
        );

        await setResult(true);
    };

    return (
        <SearchBox
            onFocus={e => setResult(true)}
            onMouseEnter={async e => {
                await setResult(true);
                await searchInput.current?.focus();
            }}
            onMouseLeave={async () => {
                await setResult(false);
                await searchInput.current?.blur();
            }}
        >
            <form onKeyUp={onChangeInput}>
                <SearchInput
                    ref={searchInput}
                    id="search"
                    type="text"
                    placeholder={'학생 검색 (이름, 전화번호)'}
                    value={keyword}
                    onChange={e => setKeyword(e.target.value)}
                    autoComplete="off"
                ></SearchInput>
                {data && result && (
                    <CancleButton
                        onClick={async () => {
                            await setResult(false);
                            await setKeyword('');
                        }}
                    >
                        <Icon
                            img={CancelButtonIcon}
                            width={'10px'}
                            height={'10px'}
                        />
                    </CancleButton>
                )}
            </form>
            {data && result && (
                <Results>
                    <ul>
                        {keyword === '' ? (
                            <li>
                                <a href="#redirect">
                                    검색어를 입력해주세요 (학생 및 보호자의 이름
                                    또는 전화번호)
                                </a>
                            </li>
                        ) : data.length > 0 ? (
                            <Fragment>
                                {data.map((student: dataInterface) => {
                                    return (
                                        <li key={student?.id}>
                                            <Link
                                                to={`/students/${student?.id}`}
                                            >
                                                {student.name} {student.phone}
                                                <span>
                                                    ({student.parent?.name}{' '}
                                                    {student.parent?.phone})
                                                </span>
                                            </Link>
                                        </li>
                                    );
                                })}
                            </Fragment>
                        ) : (
                            <li>
                                <a href="#redirect">검색 결과가 없습니다</a>
                            </li>
                        )}
                    </ul>
                </Results>
            )}
        </SearchBox>
    );
};
