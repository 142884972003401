import React, { useState, useContext, useEffect } from 'react';
import { BoxHeader } from './BusServiceStatus';
import styled from 'styled-components';
import { media } from 'styles/media';
import { PopUp } from 'components/layout/PopUp';
import { NoticeDetail } from 'components/notice/NoticeDetail';
import { DashboardContext } from 'context/dashboard';
import NoticeRequest from 'http/notice';
const noticeRequest = new NoticeRequest();

interface NoticeProps {}

const NoticeWrap = styled.div`
    ${BoxHeader} {
        margin-bottom: 0;
    }
`;
const BoxBody = styled.div``;

const NoticeList = styled.ul`
    max-height: 286px;
    ${media.desktop} {
        height: 286px;
    }
`;

const NoticeLi = styled.li`
    padding: 20px 12px;
    border-bottom: 1px solid #e1e1e1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    :hover {
        border-radius: 10px;
        -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
    }
    ${media.tablet} {
        padding: 20px 15px;
    }
`;

const Title = styled.div`
    flex: 1;
`;

const Date = styled.div`
    color: ${({ theme }) => theme.colors.grey};
    padding-left: 12px;
`;

export const Notice: React.FC<NoticeProps> = () => {
    const [noticeId, setNoticeId] = useState(0);
    const { noticePopUp, setNoticePopUp } = useContext(DashboardContext);
    const [data, setData] = useState([]);

    // 30초 마다 공지사항 갱신
    useEffect(() => {
        const getData = async () => {
            noticeRequest.getPartnerNotices(
                {
                    page: 0,
                    size: 5,
                },
                (response: any) => {
                    if (
                        response.status === -1 ||
                        (response.status === 200 && response.data.code !== 'OK')
                    ) {
                        alert(
                            `[${response.data.code}] ${response.data.message}`
                        );
                    } else {
                        setData(response.data.data.content);
                    }
                },
                (error: any) => {}
            );
        };
        getData();

        const timer = setInterval(getData, 30000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <NoticeWrap>
            <BoxHeader>
                <h2>공지사항</h2>
            </BoxHeader>
            <BoxBody>
                <NoticeList>
                    {data.map((notice: any) => {
                        return (
                            <NoticeLi
                                key={notice.id}
                                onClick={() => {
                                    setNoticeId(Number(notice.id));
                                    setNoticePopUp(true);
                                }}
                            >
                                <Title>{notice.noticeTitle}</Title>
                                <Date>{notice.publishDate}</Date>
                            </NoticeLi>
                        );
                    })}
                </NoticeList>
            </BoxBody>

            <PopUp
                id={noticePopUp}
                handler={setNoticePopUp}
                title={'공지사항'}
                contents={<NoticeDetail id={noticeId} />}
            />
        </NoticeWrap>
    );
};
